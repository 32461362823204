import axios from 'axios'
import { ElMessage } from 'element-plus'
const service = axios.create({
    // baseURL: 'http://192.168.1.115:8000/authenticator', // url = base url + request url
    baseURL: 'https://iam.turingthink.com/authenticator', // url = base url + request url
    // baseURL: '/authenticator',
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 100000 // request timeout
})
service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
            // Loading.close();
        switch (res.code) {
            case 200:
                return Promise.resolve(res)
            default:
                ElMessage(res.message)
                return Promise.resolve(res)
        }
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default service