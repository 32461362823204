<template>
  <div class="login">
    <div>
      <img src="@/assets/images/bgimg2.png" alt="" class="bgimg" />
      <div class="container">
        <div class="main">
          <div style="padding: 0px 10px; position: relative">
            <div class="font">{{ d("message.login") }}</div>
            <el-form :model="form" :rules="rules" ref="form">
              <el-tabs v-model="customActiveKey" @tab-click="handleClick">
                <!-- <el-tab-pane :label="d('message.tab1')" name="tab1">
                  <el-form-item prop="account">
                    <el-input
                      size="large"
                      type="text"
                      :placeholder="d('message.account')"
                      prefix-icon="el-icon-user"
                      v-model="form.account"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="password">
                    <el-input
                      size="large"
                      type="text"
                      show-password
                      :placeholder="d('message.password')"
                      prefix-icon="el-icon-lock"
                      v-model="form.password"
                    ></el-input>
                  </el-form-item>
                </el-tab-pane> -->
                <el-tab-pane :label="d('message.tab2')" name="tab2">
                  <el-form-item prop="phone">
                    <el-input
                      size="large"
                      type="text"
                      :placeholder="d('message.phone')"
                      prefix-icon="el-icon-mobile-phone"
                      v-model="form.phone"
                    ></el-input>
                  </el-form-item>
                  <el-row :gutter="16">
                    <el-col class="gutter-row" :span="16">
                      <el-form-item prop="code">
                        <el-input
                          size="large"
                          type="text"
                          :placeholder="d('message.code')"
                          prefix-icon="el-icon-message"
                          v-model="form.code"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col class="gutter-row" :span="8">
                      <el-button
                        class="getCaptcha"
                        :disabled="state.smsSendBtn"
                        @click.stop.prevent="getCaptcha"
                        >{{
                          (!state.smsSendBtn && d("message.getCode")) ||
                          state.time + " s"
                        }}</el-button
                      >
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
              <el-form-item style="margin-top: 24px">
                <el-button
                  size="medium"
                  type="primary"
                  htmlType="submit"
                  class="login-button"
                  @click="submitForm"
                  >{{ d("message.confirmBtn") }}</el-button
                >
              </el-form-item>
              <div class="user-login-other">
                <div class="register" @click="toRegister">
                  {{ d("message.registerAccount") }}
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_top">
        <div>官网</div>
        <div>社区</div>
        <div>专业版</div>
      </div>
      <div style="margin-top: 20px">Copyright @ 图灵信科科技有限公司</div>
    </div>
  </div>
</template>
<script>
import { login, loginCode, getCode } from "../api/login";
export default {
  name: "login",
  data() {
    return {
      form: {},
      customActiveKey: "tab2",
      state: {
        time: 60,
        loginType: 0,
        smsSendBtn: false,
      },
      rules: {
        account: [
          {
            required: true,
            pattern: /^1[34578]\d{9}$/,
            message: "请输入正确的账号/手机号",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        phone: [
          {
            required: true,
            pattern: /^1[34578]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      appId: "",
      d: this.$t,
      arrObj: "",
    };
  },
  created() {
    let url = window.location.href;
    let arrObj = url.split("?");
    this.arrObj = arrObj[1];
    if (arrObj.length > 1) {
      let arrPara = arrObj[1]
        .split("&")
        .map((query) => query.split("="))
        .reduce((key, val) => ((key[val[0]] = val[1] || ""), key), {});
      console.log("参数", arrPara);
      this.appId = arrPara.appId;
    }
  },
  methods: {
    getCaptcha() {
      this.$refs["form"].validateField("phone", async (valid) => {
        if (!valid) {
          this.state.smsSendBtn = true;
          await getCode(this.form.phone);
          const interval = window.setInterval(() => {
            if (this.state.time-- <= 0) {
              this.state.time = 60;
              this.state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);
        }
      });
    },
    async submitForm() {
      const validateFieldsKey =
        this.customActiveKey === "tab1"
          ? ["account", "password"]
          : ["phone", "code"];
      let isError = 0;
      this.$refs["form"].validateField(validateFieldsKey, (valid) => {
        if (valid) {
          isError = 1;
        }
      });
      if (isError === 0) {
        if (this.customActiveKey === "tab1") {
          const data = {
            account: this.form.account,
            password: this.form.password,
            appId: this.appId,
          };
          const res = await login(data);
          if (res.code == 200) {
            this.$router.push("/?" + this.arrObj);
          } else if (res.code == 456) {
            this.$router.push("/login?" + this.arrObj);
          }
        } else {
          const data = {
            phone: this.form.phone,
            code: this.form.code,
            appId: this.appId,
          };
          const res = await loginCode(data);
          if (res.code == 200) {
            this.$router.push("/?" + this.arrObj);
          } else if (res.code == 456) {
            this.$router.push("/login?" + this.arrObj);
          }
        }
      }
    },
    toRegister() {
      this.$router.push("/register?" + this.arrObj);
    },
    handleClick(tab) {
      this.customActiveKey = tab.props.name;
    },
  },
};
</script>

<style>
/* pc端 */
@media screen and (min-width: 720px) {
  .bgimg {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    max-height: 100vh;
    width: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .login {
    min-height: 100vh;
    background: #f0f2f5;
    position: relative;
  }
  .container {
    width: 100%;
    min-height: 100vh;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 260px;
    width: 368px;
    min-height: 100vh;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.6);
  }
  .font {
    font-size: 22px;
    margin-bottom: 20px;
  }
  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }
  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;
    height: 50px;
  }
  .register {
    float: right;
    color: #1890ff;
    font-size: 14px;
  }
  .el-tabs__nav-wrap::after {
    width: 0;
  }
  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }
  .bottom {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #666666;
    font-size: 14px;
    margin-bottom: 20px;
    width: 100%;
    bottom: 0;
    z-index: 1000;
  }
  .bottom_top {
    display: flex;
    width: 200px;
    justify-content: space-around;
  }
}
/* 移动端 */
@media screen and (max-width: 720px) {
  .bgimg {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    max-height: 100vh;
    width: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .login {
    min-height: 100vh;
    background: #f0f2f5;
    position: relative;
  }
  .container {
    width: 100%;
    min-height: 100vh;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 260px;
    width: 100%;
    min-height: 100vh;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.9);
  }
  .font {
    font-size: 22px;
    margin-bottom: 20px;
  }
  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 0.4rem;
    width: 100%;
  }
  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 0.22rem;
    height: 0.5rem;
  }
  .register {
    float: right;
    color: #1890ff;
    font-size: 14px;
  }
  .el-tabs__nav-wrap::after {
    width: 0;
  }
  .getCaptcha {
    display: block;
    width: 100%;
    height: 0.4rem;
  }
  .bottom {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #666666;
    font-size: 14px;
    margin-bottom: 20px;
    width: 100%;
    bottom: 0;
    z-index: 1000;
  }
  .bottom_top {
    display: flex;
    width: 200px;
    justify-content: space-around;
  }
}
</style>
