import { createRouter, createWebHashHistory } from 'vue-router'
import login from '../views/login.vue'
import auth from '../views/auth.vue'
const routes = [{
        path: '/',
        name: 'auth',
        meta: {
            title: '授权'
        },
        component: auth
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录'
        },
        component: login
    },
    {
        path: '/register',
        name: 'Register',
        meta: {
            title: '注册'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/register.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router