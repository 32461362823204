import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import VueWechatTitle from 'vue-wechat-title';
import ElementPlus from 'element-plus';
import { createI18n } from 'vue-i18n'
import enLocale from 'element-plus/lib/locale/lang/en'
import zhLocale from 'element-plus/lib/locale/lang/zh-cn'
const messages = {
    'en': {
      // el 这个属性很关键，一定要保证有这个属性，
      el: enLocale.el,
      // 定义您自己的字典，但是请不要和 `el` 重复，这样会导致 ElementPlus 内部组件的翻译失效.
      message: {
        hello: 'hello world',
        confirmBtn:'Confirm',
        registerAccount:'Register',
        account:'Enter Your Account',
        password:'Enter Your Password',
        register:'Register',
        login:'Login',
        phone:'Phone',
        code:'Code',
        getCode:'GetCode',
        pwd:'At least six passwords, case sensitive',
        surePwd:'Confirm password',
        text:'Login existing account',
        tab1:'LoginOfCode',
        tab2:'LoginOfPhone',
        auth:'Authorize',
        authStates1:'Please onfirm the authorization information',
        authStates2:'Get information about you (avatar, nickname, etc.)',
        authStates3:'Authorized To Log In',
        switchAccount:'Switch Account'
      },
    },
    'zh': {
      el: zhLocale.el,
      message: {
        hello: '你好，世界',
        confirmBtn:'确定',
        registerAccount:'注册账号',
        account:'请输入账号',
        password:'请输入密码',
        register:'注册',
        login:'用户登录',
        phone:'手机号',
        code:'验证码',
        getCode:'获取验证码',
        pwd:'至少六位密码，区分大小写',
        surePwd:'确认密码',
        text:'使用已有账户登录',
        tab1:'账号密码登录',
        tab2:'手机号登录',
        auth:'同意授权',
        authStates1:'请确认授权信息',
        authStates2:'获取你的信息（头像，昵称等）',
        authStates3:'授权登录',
        switchAccount:'切换账号'
      },
    },
    testLocale: {
      el: {},
      // 没有定义 message 字段，会 fallback 回到 en 去, fallbackLocale 的定义在下方 👇
    },
  }
  
  const i18n = createI18n({
    locale: 'zh',
    fallbackLocale: 'en',
    messages,
  })
  
createApp(App).use(router).use(VueWechatTitle).use(ElementPlus, {
    i18n: i18n.global.t,
  }).use(i18n).mount('#app')