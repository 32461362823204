import request from "../utils/request"
export function login(data) {
    return request({
        url:"/v1/phone/login",
        method:"post",
        data
    })
}

export function getCode(data) {
    return request({
        url:"/v1/phone/login/code?phone="+data,
        method:"get",
    })
}

export function getRegisterCode(data) {
    return request({
        url:"/v1/phone/register/code?phone="+data,
        method:"get",
    })
}getRegisterCode

export function loginCode(data) {
    return request({
        url:"/v1/phone/code/login",
        method:"post",
        data
    })
}

export function register(data) {
    return request({
        url:"/v1/phone/register",
        method:"post",
        data
    })
}

export function getProfile() {
    return request({
        url:"/v1/profile",
        method:"get",
    })
}

export function getApplication(appId) {
    return request({
        url:"/v1/sso/applications/"+appId,
        method:"get",
    })
}

export function getAuthorize(data) {
    return request({
        url:"/v1/sso/authorize",
        method:"get",
        params:data
    })
}getAuthorize