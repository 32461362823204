<template>
  <div class="auth">
    <div class="qiehuan" @click="switchAccount">{{ d("message.switchAccount") }}</div>
    <img src="@/assets/images/bgimg.png" alt="" class="bgimg" />
    <div class="center">
      <div class="test">{{ d("message.authStates3") }}</div>
      <div style="display: flex; align-items: center; flex-direction: column">
        <img
          src="@/assets/images/actor.png"
          class="cover"
        />
        <div style="margin-top: 40px; font-weight: 600">
          {{ d("message.authStates1") }}
        </div>
        <div style="margin-top: 20px; color: #999">
          {{ d("message.authStates2") }}
        </div>
        <el-button
          size="medium"
          type="primary"
          htmlType="submit"
          class="auto-button"
          @click="submitForm"
          >{{ d("message.auth") }}</el-button
        >
      </div>
      <div class="bott">
        <div class="bottom_top">
          <div>帮助</div>
          <div>隐私</div>
          <div>条款</div>
        </div>
        <div style="margin-top: 20px">Copyright @ 图灵信科科技有限公司</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getProfile, getApplication, getAuthorize } from "../api/login";
export default {
  name: "auth",
  data() {
    return {
      redirect_uri: "",
      appId: "",
      scope: "",
      responseType: "",
      d: this.$t,
      userInfo: {},
      applicationInfo: {},
      arrObj: "",
    };
  },
  created() {
    let url = window.location.href;
    let arrObj = url.split("?");
    this.arrObj = arrObj[1];
    if (arrObj.length > 1) {
      let arrPara = arrObj[1]
        .split("&")
        .map((query) => query.split("="))
        .reduce((key, val) => ((key[val[0]] = val[1] || ""), key), {});
      //console.log("参数", arrPara);
      this.redirect_uri = arrPara.redirect_uri;
      this.appId = arrPara.appId;
      this.scope = arrPara.scope;
      this.responseType = arrPara.response_type;
    }
    this.getProfile();
   
  },
  methods: {
    async submitForm() {
      let data = {
        redirectUri: this.redirect_uri,
        appId: this.appId,
        responseType: this.responseType,
        scope: this.scope,
      };
      const res = await getAuthorize(data);
      //console.log(res);
      if (res.code == 200) {
        window.location.href = res.data.redirectUri;
      } else if (res.code == 456) {
        this.$router.push("/login?" + this.arrObj);
      }
    },
    async getProfile() {
      await getProfile()
        .then((res) => {
          if (res.code == 200) {
            this.userInfo = res.data;
            this.getApplication();
          } else if (res.code == 456) {
            this.$router.push("/login?" + this.arrObj);
          }
        })
        .catch((error) => {
          if (error.response.status == 403) {
            this.$router.push("/login?" + this.arrObj);
          }
        });
    },
    async getApplication() {
      const res = await getApplication(this.appId);
      console.log('请求',res);
      if (res.code == 200) {
        this.applicationInfo = res.data;
      } else if (res.code == 456) {
        this.$router.push("/login?" + this.arrObj);
      }
    },
    switchAccount(){
      this.$router.push("/login?" + this.arrObj);
    },
  },
};
</script>

<style>
/* pc端 */
@media screen and (min-width: 720px) {
  .auth {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .qiehuan{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    z-index: 1000;
    cursor:default;
  }
  .test {
    color: #333333;
    font-size: 32px;
  }
  .bgimg {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    max-height: 100vh;
    width: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    margin-top: 80px;
    justify-content: space-between;
  }
  .cover {
    width: 140px;
    height: 140px;
    border-radius: 50%;
  }
  button.auto-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 50%;
    margin-top: 20px;
  }
  .bott {
    display: flex;
    flex-direction: column;
    color: #666666;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .bottom_top {
    display: flex;
    width: 200px;
    justify-content: space-around;
  }
}
/* 移动端 */
@media screen and (max-width: 720px) {
  .auth {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .qiehuan{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    z-index: 1000;
  }
  button.auto-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 50%;
    margin-top: 15px;
  }
  .test {
    color: #333333;
    font-size: 32px;
  }
  .bgimg {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    max-height: 100vh;
    width: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    margin-top: 80px;
    justify-content: space-between;
  }
  .cover {
    width: 140px;
    height: 140px;
    border-radius: 50%;
  }
  .bott {
    display: flex;
    flex-direction: column;
    color: #666666;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .bottom_top {
    display: flex;
    width: 200px;
    justify-content: space-around;
  }
}
</style>
